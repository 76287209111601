body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --primary-text-color: #eeeeee;
  --secondary-text-color: #4695d6;

  --primary-bg-color: rgb(68, 68, 68);
  --secondary-bg-color: #222222;
}




.column {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  flex-direction: row;
}

.centerAlign {
  display: flex;
  align-items: center;
  justify-content: center;
}

.evenSpacing {
  justify-content: space-around;
}

.resetLink {
  color: var(--primary-text-color);
  text-decoration: none;
}



@keyframes fade-in-left {
  0% {
    opacity: 0;
    transform: translateX(20vw);
  }
  20% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}



/*LEADERBOARD*/

.teamCard {
  width: 80vw;
  position: absolute;

  padding: 0.5vh 1vw;

  margin-top: 1vh;

  display: flex;
  align-items: center;

  color: var(--secondary-bg-color);

  background-color: rgba(255,255,255, 0.7);
  border-radius: 2vw;

  animation: 1.5s fade-in-left ease-in-out both;
  transition: 1s;
}

.teamCard h1 {
  font-size: 2vw;
  text-decoration: none;
}

.teamCard h2 {
  margin: 0;
  font-size: 1vw;
  text-decoration: none;
}

.pfp {
  margin: 1vh 1vw;

  border-radius: 100%;
}

.teamCard .pfp {
  width: 7vw;
  height: 7vw;
}

.teamCard:last-child {
  margin-bottom: 3vh;
}

.first {
  top: 0;
  background: linear-gradient(45deg, rgba(223,189,105,1) 0%, rgba(146,111,52,1) 100%);
}

.second {
  top: 0vh;
}

.third {
  top: 0vh;
}

.fourth {
  top: 0vh
}

.noPlace {
  top: 100vh
}


/*PROBLEMS*/
line {
  width: 25vw;
}

.treeContainer {
  position: relative;

  color: var(--primary-text-color);

  overflow: "hidden";
}

.treeNode {
  position: relative;

  width: 5vw;
  height: 5vw;

  font-size: max(1vw, 1vh);

  border-radius: 50%;

  transition: 0.2s ease-out;
}

.disabled {
  background-color: var(--primary-bg-color);

  box-shadow: 0.2vw 0.2vw var(--primary-text-color);
}

.active {
  background-color: var(--secondary-text-color);

  box-shadow: 0.2vw 0.2vw var(--primary-bg-color);
}

.solved {
  background-color: #00ff99;
  color: #333;
  box-shadow: 0.2vw 0.2vw var(--primary-bg-color);
}